import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home/Home";
import About from "./about/About"
import Projects from "./projects/Projects"
import KpDevLayout from "./home/KpDevLayout"

function App() {
  return (
    <BrowserRouter>
      <KpDevLayout>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/projects" element={<Projects/>} />
        </Routes>
      </KpDevLayout>
    </BrowserRouter>
  );
}

export default App;
