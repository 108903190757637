import logo from "./logo.png"

export default function Logo({ size }) {
  const logoStyle = {
    height: `${size}vh`,
  };

  return (
    <img src={logo} alt="logo" style={logoStyle} />
  );
}