import { Link } from "react-router-dom";


export default function Menu() {
    return (
        <>
            <Link to="about">About</Link>
            <Link to="projects">Projects</Link>
        </>
    )
}