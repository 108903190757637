import LogoSpinner from "../logo/LogoSpinner";

function Home() {
  return (
    <div>
        <LogoSpinner size={40}/>
        <div>Welcome to KP-Dev.</div>
    </div>
  );
}

export default Home;