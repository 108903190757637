import logo from "./logo.png"
import "./logo.css"


export default function LogoSpinner({ size }) {
  const logoStyle = {
    height: `${size}vh`,
  };

  return (
    <img src={logo} className="App-logo" alt="logo" style={logoStyle} />
  );
}