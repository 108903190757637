import "./home.css"
import "../shared.css"
import Logo from "../logo/Logo";
import Menu from "../menu/Menu";
import { Link } from "react-router-dom";

export default function KpDevLayout({children}) {
    return (
        <div>
            <header>
                <Link to="/" className="horizontal-container">
                    <Logo size={5} />
                    <span className="padding-left">Katy Perry Development</span>
                </Link>
            </header>
            <main>
                {children}
            </main>
            <nav className="horizontal-container space-evenly">
                <Menu />
            </nav>
        </div>
    )
}